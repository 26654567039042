import React from "react";
import styled from '@emotion/styled';
import Button from "../../../../components/general/Button";
import ModalHeader from "../../../../components/general/ModalHeader";
import FooterBar from "../../../../components/structure/FooterBar";
import ModalLayout from "../../../../components/structure/ModalLayout";
import { style_border_default } from "../../../../constants/stylesValues";
import { color_gradients_green_shift, color_shades_darkest, color_text_link } from "../../../../constants/colors";
import { css } from "@emotion/css";
import { FormikProps } from "formik";
import useWindowSize from "../../../../hooks/UseWindowSize";
import CustomScrollbars from "../../../../components/structure/CustomScrollbars";
import { TelephonyDeploymentProps } from "./TelephonyDeploymentForm";
import { TelephonyDeploymentFormData } from "./TelephonyDeploymentFormData";
import TextField from "../../../../components/forms/TextField";
import StyledDropdown from "../customAssistantConfigurations/StyledDropdown";
import RadioGroup from "../../../../components/forms/RadioGroup";
import { autoTransferPreviouslyTransferredOptions, attemptInitialTransferOptions, callRecordingOptions, disableBargeInOptions, disableSpamFilterOptions, disableSpeechStartedOptions, disableWelcomeMessageBargeInOptions, googleVoiceOptions, initialTransferWithCallingNumberOptions, mergeTurnsAfterPausesOptions, noTrackingOptions, useProactiveOptions, virtualNumberOptions } from "./TelephonyDeploymentFormFieldOptions";
import ContentCollapsingSection from "../../../../components/structure/ContentCollapsingSection";
import { formatPhoneNumber } from "../../../../../clients/spark/scenes/basicOrganizationApplications/components/ApplicationItem";
import { availableASRProviders } from "../../../../constants/telephonyConstants.tsx/defaultTelephonyDeploymentSettings";
import { defaultVoice, defaultRimeVoice } from "../../../../constants/sparkConstants/voices";
import { defaultTelephonyDeploymentSettings } from '../../../../constants/telephonyConstants.tsx/defaultTelephonyDeploymentSettings';

const advancedSettingsIcon = require('../../../../content/images/application-settings/advanced-settings.svg');

const MODAL_SCROLLBAR_SUBTRACTION: number = 200;

const processAudioOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
];

const countryOptions = [
    { label: "United States", value: "US" },
    { label: "Canada", value: "CA" }
];

const TelephonyDeployment: React.FC<TelephonyDeploymentProps & FormikProps<TelephonyDeploymentFormData>> = (props) => {
    const windowSize = useWindowSize();

    const handleOnChange = (optionValue: string, value: string | boolean) => {
        props.setFieldValue(optionValue, value);
    };

    const handleVoiceDropdownChange = (optionValue: string, value: string | boolean) => {
        if (value === "Custom") {
            props.setFieldValue(optionValue, value);
        }
        else {
            props.setFieldValue("ttsProvider", "Google");
            props.setFieldValue(optionValue, value);
        }
    };

    const handleDropDownChange = (optionValue: string, value: string | boolean) => {
        props.setFieldValue(optionValue, value);
    };

    const handleCountryChange = (optionValue: string, value: string) => {
        props.setFieldValue("country", value);
    };

    const getLocaleOptions = () => {
        const localeOptions = props.appContainer?.state?.currentApp?.languages.map((language) => {
            return { label: language.shortCode, value: language.shortCode }
        });
        return localeOptions;
    };

    const handlePhoneNumberStartsWithBlur = () => {
        if (props.values.phoneNumberStartsWith[0] !== "1") {
            props.setFieldValue("phoneNumberStartsWith", 1 + props.values.phoneNumberStartsWith)
        }
        props.handleBlur;
    };

    const usingCustomVoice = props.values.ttsProvider !== "Google" || props.values.ttsVoice === "Custom" || !googleVoiceOptions.some(o => o.value === props.values.ttsVoice);
    return (
        <ModalLayout isVisible={true}>
            {
                !props.selectedTelephonyDeployment?.phoneNumber ?
                    <ModalHeader title="Configure a Phone Number" onClose={() => props.closeTelephonyRequestForm()} />
                    :
                    <ModalHeader title={`Configure ${formatPhoneNumber(props.selectedTelephonyDeployment.phoneNumber, !!props.selectedTelephonyDeployment?.virtualNumber?.length)}`} onClose={() => props.closeTelephonyRequestForm()} />
            }
            <form onSubmit={props.handleSubmit} >
                <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                    <ContentWrapper>
                        <SectionCard>
                            <SectionTitle>Basic Configuration</SectionTitle>
                            <CustomTextField
                                name="name"
                                value={props.values.name}
                                label="Name"
                                placeholder="Enter a name for this telephony deployment here"
                                onChange={props.handleChange}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                            <RadioGroupLabel>Track Analytics</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("noTracking", e.value)}
                                value={props.values.noTracking}
                                options={noTrackingOptions}
                            />
                            <RadioGroupLabel>Record Calls</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("callRecordingEnabled", e.value)}
                                value={props.values.callRecordingEnabled}
                                options={callRecordingOptions}
                            />
                        </SectionCard>

                        {(!props.selectedTelephonyDeployment?.phoneNumber) &&
                            <SectionCard>
                                <SectionTitle>Phone Number Configuration</SectionTitle>
                                <RadioGroupLabel>Phone Number Type</RadioGroupLabel>
                                <CustomRadioGroup
                                    checkContainerStyle={checkContainerStyle}
                                    selectedOptionStyle={selectedOptionStyle}
                                    onChange={(e) => handleOnChange("useVirtualNumber", e.value)}
                                    value={props.values.useVirtualNumber}
                                    options={virtualNumberOptions}
                                />
                                {
                                    (!props.values.useVirtualNumber) &&
                                    <>
                                        <Label>Preferred Country Code and Area Code</Label>
                                        <PhoneNumberRow>
                                            <CountryDropdownWrapper>
                                                <CustomDropdown
                                                    handleOnChange={handleCountryChange}
                                                    fieldName="country"
                                                    options={countryOptions}
                                                    size="medium"
                                                    value={props.values.country || "US"}
                                                />
                                            </CountryDropdownWrapper>
                                            <PhoneNumberWrapper>
                                                <CustomTextField
                                                    name="phoneNumberStartsWith"
                                                    value={props.values.phoneNumberStartsWith}
                                                    placeholder="Enter your preferred country code and area code here e.g. 1508"
                                                    onChange={props.handleChange}
                                                    onBlur={handlePhoneNumberStartsWithBlur}
                                                    fieldContainerStyle={fieldContainerStyle}
                                                />
                                            </PhoneNumberWrapper>
                                        </PhoneNumberRow>
                                    </>
                                }
                                {
                                    (props.values.useVirtualNumber) &&
                                    <CustomTextField
                                        name="virtualNumber"
                                        value={props.values.virtualNumber}
                                        label={!props.selectedTelephonyDeployment?.phoneNumber?.length ? "Enter virtual number (Leave blank to generate)" : "Enter updated virtual number"}
                                        placeholder="Enter your preferred unique 6-12 digit virtual number here"
                                        onChange={props.handleChange}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />
                                }
                            </SectionCard>
                        }

                        <SectionCard>
                            <SectionTitle>Voice & Language Settings</SectionTitle>
                            <LabelWrapper>
                                <Label>Google Text-to-Speech Voice</Label>
                                <LabelLink href="https://cloud.google.com/text-to-speech/docs/voices" target="_blank">(more info)</LabelLink>
                            </LabelWrapper>
                            <CustomDropdown
                                handleOnChange={handleVoiceDropdownChange}
                                fieldName="ttsVoice"
                                options={googleVoiceOptions}
                                size="medium"
                                searchable={true}
                                className={dropDownStyleMedium}
                                value={usingCustomVoice ? "Custom" : props.values.ttsVoice}
                            />
                            {
                                (usingCustomVoice) &&
                                <>
                                    <CustomVoiceRow>
                                        <CustomVoiceRowLeft
                                            name="ttsProvider"
                                            value={props.values.ttsProvider}
                                            label="TTS Provider"
                                            labelStyle={labelStyle}
                                            placeholder="Enter your tts provider here"
                                            onChange={props.handleChange}
                                            fieldContainerStyle={fieldContainerStyle}
                                        />
                                        <CustomVoiceRowRight
                                            name="ttsVoice"
                                            value={props.values.ttsVoice}
                                            labelStyle={labelStyle}
                                            label={"Voice"}
                                            placeholder={props.values.ttsProvider?.toLowerCase() === "elevenlabs" ? defaultVoice
                                                : props.values.ttsProvider?.toLowerCase() === "rime" ? defaultRimeVoice
                                                    : ""}
                                            onChange={props.handleChange}
                                            fieldContainerStyle={fieldContainerStyle}
                                        />
                                    </CustomVoiceRow>
                                </>
                            }
                            <LabelWrapper>
                                <Label>Locale</Label>
                            </LabelWrapper>
                            {
                                getLocaleOptions().length > 1 ?
                                    <CustomDropdown
                                        handleOnChange={handleOnChange}
                                        fieldName="locale"
                                        options={getLocaleOptions()}
                                        size="medium"
                                        className={dropDownStyleMedium}
                                        value={props.values.locale}
                                    />
                                    :
                                    <CustomDropdown
                                        handleOnChange={handleOnChange}
                                        fieldName="locale"
                                        options={[{ label: "en-US", value: "en-US" }]}
                                        size="medium"
                                        className={dropDownStyleMedium}
                                        value={props.values.locale}
                                    />
                            }
                        </SectionCard>

                        <SectionCard>
                            <SectionTitle>Timing & Delay Settings</SectionTitle>
                            <CustomTextField
                                name="firstMessageDelayMS"
                                disabled={false}
                                tooltip="Number in milliseconds before the first assistant message is played"
                                toolTipPosition="right"
                                value={props.values.firstMessageDelayMS}
                                label="First Message Delay (Milliseconds)"
                                placeholder="Enter a number in miliseconds here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                            <CustomTextField
                                name="asrEndpointing"
                                tooltip="Number in milliseconds between when the caller is done talking and Voicify starts processing input"
                                toolTipPosition="right"
                                disabled={false}
                                value={props.values.asrEndpointing}
                                label="ASR Endpointing (Milliseconds)"
                                type="number"
                                placeholder="Enter a number in miliseconds here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                        </SectionCard>

                        <SectionCard>
                            <SectionTitle>Barge-In Settings</SectionTitle>
                            <RadioGroupLabel>Use Barge In</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("disableBargeIn", e.value)}
                                value={props.values.disableBargeIn}
                                options={disableBargeInOptions}
                            />
                            {
                                !props.values.disableBargeIn &&
                                <>
                                    <RadioGroupLabel>Allow Welcome Message Barge In</RadioGroupLabel>
                                    <CustomRadioGroup
                                        checkContainerStyle={checkContainerStyle}
                                        selectedOptionStyle={selectedOptionStyle}
                                        onChange={(e) => handleOnChange("disableWelcomeMessageBargeIn", e.value)}
                                        value={props.values.disableWelcomeMessageBargeIn}
                                        options={disableWelcomeMessageBargeInOptions}
                                    />
                                    <RadioGroupLabel>Merge Turns After Pauses</RadioGroupLabel>
                                    <CustomRadioGroup
                                        checkContainerStyle={checkContainerStyle}
                                        selectedOptionStyle={selectedOptionStyle}
                                        onChange={(e) => handleOnChange("mergeTurnsAfterPauses", e.value)}
                                        value={props.values.mergeTurnsAfterPauses}
                                        options={mergeTurnsAfterPausesOptions}
                                    />
                                    <CustomTextField
                                        tooltip="The maximum duration of pause between caller utterances that qualifies them as a single utterance"
                                        toolTipPosition="right"
                                        name="mergeTurnTimeoutMS"
                                        type="number"
                                        disabled={false}
                                        value={props.values.mergeTurnTimeoutMS}
                                        label="Merge Turn Timeout (Milliseconds)"
                                        placeholder="Enter a number in miliseconds here"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />
                                    <RadioGroupLabel>Use Speech Started Event</RadioGroupLabel>
                                    <CustomRadioGroup
                                        checkContainerStyle={checkContainerStyle}
                                        selectedOptionStyle={selectedOptionStyle}
                                        onChange={(e) => handleOnChange("disableSpeechStarted", e.value)}
                                        value={props.values.disableSpeechStarted}
                                        options={disableSpeechStartedOptions}
                                    />
                                    {
                                        !props.values.disableSpeechStarted &&
                                        <>
                                            <CustomTextField
                                                tooltip='The confidence level at which the assistant will consider the user to be speaking. 0.0 is silence, 1.0 is full volume/confidence, 0.9 is the default.'
                                                toolTipPosition="right"
                                                name="speechStartedLevel"
                                                type="number"
                                                disabled={false}
                                                value={props.values.speechStartedLevel}
                                                label="Speech started level"
                                                placeholder="Enter a number from 0.0 to 1 here"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                fieldContainerStyle={fieldContainerStyle}
                                            />
                                            <CustomTextField
                                                tooltip="The time in milliseconds that the assistant will pause after a speech started event."
                                                toolTipPosition="right"
                                                name="speechStartedPauseMS"
                                                type="number"
                                                disabled={false}
                                                value={props.values.speechStartedPauseMS}
                                                label="Speech started pause milliseconds"
                                                placeholder="Enter a number in MS here"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                fieldContainerStyle={fieldContainerStyle}
                                            />
                                            <CustomTextField
                                                tooltip="The maximum number of uncomfirmed speech started events during an assistant utterance before speech started is turned off"
                                                toolTipPosition="right"
                                                name="speechStartedUnconfirmedBargeInLimit"
                                                type="number"
                                                disabled={false}
                                                value={props.values.speechStartedUnconfirmedBargeInLimit}
                                                label="Unconfirmed speech started event limit"
                                                placeholder="Enter a number here"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                fieldContainerStyle={fieldContainerStyle}
                                            />
                                            <CustomTextField
                                                tooltip='The number of consecutive "speaking" audio buffers before speech started event is fired.'
                                                toolTipPosition="right"
                                                name="speechStartedRequiredBufferCount"
                                                type="number"
                                                disabled={false}
                                                value={props.values.speechStartedRequiredBufferCount}
                                                label="Speech started required audio buffer count"
                                                placeholder="Enter a number here"
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                fieldContainerStyle={fieldContainerStyle}
                                            />
                                        </>
                                    }
                                </>
                            }
                        </SectionCard>

                        <SectionCard>
                            <SectionTitle>Proactive Response Settings</SectionTitle>
                            <RadioGroupLabel>Use Proactive Responses</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("useProactiveResponses", e.value)}
                                value={props.values.useProactiveResponses}
                                options={useProactiveOptions}
                            />
                            {
                                props.values.useProactiveResponses &&
                                <>
                                    <CustomTextField
                                        name="proactiveResponseDelayMS"
                                        disabled={false}
                                        tooltip="How long an assistant is taking before it gives a proactive response"
                                        toolTipPosition="right"
                                        type="number"
                                        value={props.values.proactiveResponseDelayMS}
                                        label="Proactive Response Delay (Milliseconds)"
                                        placeholder="Enter a number in miliseconds here"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />
                                    <CustomTextField
                                        name="postProactiveResponsePauseDelayMS"
                                        disabled={false}
                                        tooltip="How long an assistant pauses after giving a proactive response before giving the next response if ready."
                                        toolTipPosition="right"
                                        type="number"
                                        value={props.values.postProactiveResponsePauseDelayMS}
                                        label="Post Proactive Response Delay (Milliseconds)"
                                        placeholder="Enter a number in miliseconds here"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />
                                    <CustomTextField
                                        name="proactiveResponses"
                                        tooltip="Each time a proactive response is returned it will be randomly selected from this list."
                                        toolTipPosition="right"
                                        disabled={false}
                                        value={props.values.proactiveResponses}
                                        label="Proactive responses"
                                        placeholder='Enter the proactive responses you want to use divided by a "|" here.'
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />
                                    <CustomTextField
                                        name="maxProactiveResponseAttempts"
                                        tooltip="Maximum number of times the assistant will give a proactive response for a single turn of the conversation."
                                        toolTipPosition="right"
                                        disabled={false}
                                        value={props.values.maxProactiveResponseAttempts}
                                        label="Maximum Proactive Response Attempts"
                                        placeholder='1'
                                        type="number"
                                        validate={(value) => {
                                            if (value < 1) {
                                                return "Value must be 1 or greater";
                                            }
                                        }}
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            if (!isNaN(value) && value >= 1) {
                                                props.handleChange(e);
                                            }
                                        }}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />
                                    {props.values.maxProactiveResponseAttempts > 1 && (
                                        <>
                                            <CustomTextField
                                                name="followUpProactiveResponses"
                                                tooltip="After the first proactive response, if the assistant is still waiting, it will use a follow-up response from this list."
                                                toolTipPosition="right"
                                                disabled={false}
                                                value={props.values.followUpProactiveResponses}
                                                label="Follow-up Proactive Responses"
                                                placeholder='Enter the follow-up proactive responses divided by a "|" here.'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                fieldContainerStyle={fieldContainerStyle}
                                            />
                                            <CustomTextField
                                                name="followUpProactiveResponsesDelayMS"
                                                tooltip="Delay between the follow up proactive responses"
                                                toolTipPosition="right"
                                                disabled={false}
                                                value={props.values.followUpProactiveResponsesDelayMS}
                                                label="Follow-up Proactive Responses Delay (Milliseconds)"
                                                placeholder='8000'
                                                onChange={props.handleChange}
                                                onBlur={props.handleBlur}
                                                fieldContainerStyle={fieldContainerStyle}
                                            />
                                        </>
                                    )}
                                </>
                            }
                        </SectionCard>

                        <SectionCard>
                            <SectionTitle>Reprompt Settings</SectionTitle>
                            <CustomTextField
                                name="repromptAttempts"
                                tooltip="Max number of times the assistant will reprompt before hanging up"
                                toolTipPosition="right"
                                disabled={false}
                                value={props.values.repromptAttempts}
                                label="Reprompt Attempts"
                                placeholder="Enter the max number of reprompts here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                            <CustomTextField
                                name="repromptDelaySeconds"
                                tooltip="Number of seconds of caller silence before the assistant reprompts"
                                toolTipPosition="right"
                                disabled={false}
                                value={props.values.repromptDelaySeconds}
                                label="Reprompt Delay (Seconds)"
                                placeholder="Enter the number of seconds between reprompts here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                        </SectionCard>

                        <SectionCard>
                            <SectionTitle>Spam Filter Settings</SectionTitle>
                            <RadioGroupLabel>Use Spam Filter</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("disableSpamDetection", e.value)}
                                value={props.values.disableSpamDetection}
                                options={disableSpamFilterOptions}
                            />
                            {!props.values.disableSpamDetection && (
                                <div>
                                    <CustomTextField
                                        name="spamDetectionPromptOverride"
                                        type="text"
                                        value={props.values.spamDetectionPromptOverride}
                                        label="Spam Detection Prompt (there is a default, use this to overrride)"
                                        onChange={props.handleChange}
                                    />

                                    <CustomTextField
                                        name="spamDetectionTolerance"
                                        tooltip="Minimum predicted probability to flag a call as spam"
                                        toolTipPosition="right"
                                        disabled={false}
                                        value={
                                            props.values.spamDetectionTolerance < 0
                                                ? 0.999
                                                : props.values.spamDetectionTolerance
                                        }
                                        label="Spam Detection Tolerance"
                                        placeholder="Enter a number between 0.981 to 1 (Anything below 0.981 runs a false positive risk. Leave blank for default 0.9999)"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />

                                    <CustomTextField
                                        name="spamDetectionTemperature"
                                        toolTipPosition="right"
                                        disabled={false}
                                        value={
                                            props.values.spamDetectionTemperature < 0
                                                ? 0.1
                                                : props.values.spamDetectionTemperature
                                        }
                                        label="Spam Detection Temperature"
                                        placeholder="Enter a number between 0 and 1 (Leave blank for default 0.1)"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />

                                    <CustomTextField
                                        name="spamDetectionGenAiProvider"
                                        type="text"
                                        value={props.values.spamDetectionGenAiProvider}
                                        label="Spam Detection GenAI model (defaults to 4o-mini)"
                                        onChange={props.handleChange}
                                    />
                                </div>
                            )}
                        </SectionCard>

                        <SectionCard>
                            <SectionTitle>Transfer Settings</SectionTitle>
                            <CustomTextField
                                tooltip='The phone number you want to transfer to during the initial attempt'
                                toolTipPosition="right"
                                name="initialTransferTarget"
                                disabled={false}
                                value={props.values.initialTransferTarget}
                                label="Default intitial transfer target"
                                placeholder="Enter a phone number here in format 12223334444"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                            <RadioGroupLabel>Attempt initial transfer with assistant as backup</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("attemptInitialTransfer", e.value)}
                                value={props.values.attemptInitialTransfer}
                                options={attemptInitialTransferOptions}
                            />
                            {
                                props.values.attemptInitialTransfer &&
                                <>
                                    <CustomTextField
                                        tooltip="The time in seconds that the phone will ring at the store before the assistant answers"
                                        toolTipPosition="right"
                                        name="initialTransferTimeoutSeconds"
                                        type="number"
                                        disabled={false}
                                        value={props.values.initialTransferTimeoutSeconds}
                                        label="Initial transfer ring time"
                                        placeholder="Enter a number in seconds here"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        fieldContainerStyle={fieldContainerStyle}
                                    />
                                    <RadioGroupLabel>Perform initial transfer with calling number instead of called number</RadioGroupLabel>
                                    <CustomRadioGroup
                                        checkContainerStyle={checkContainerStyle}
                                        selectedOptionStyle={selectedOptionStyle}
                                        onChange={(e) => handleOnChange("initialTransferWithCallingNumber", e.value)}
                                        value={props.values.initialTransferWithCallingNumber}
                                        options={initialTransferWithCallingNumberOptions}
                                    />
                                </>
                            }
                            <RadioGroupLabel>Auto transfer previously immediately transferred users?</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("autoTransferPreviouslyTransferred", e.value)}
                                value={props.values.autoTransferPreviouslyTransferred}
                                options={autoTransferPreviouslyTransferredOptions}
                            />
                            {props.values.autoTransferPreviouslyTransferred &&
                                <CustomTextField
                                    tooltip="If a user has been previously immediately transferred and calls back, this is the number of hours between the previous transfer and the current call that will be used to determine if the user should be automtically transferred again."
                                    toolTipPosition="right"
                                    name="immediateTransferHourCutoff"
                                    type="number"
                                    disabled={false}
                                    value={props.values.immediateTransferHourCutoff ?? defaultTelephonyDeploymentSettings.immediateTransferHourCutoff}
                                    label="Auto transfer previously immediately transferred users cutoff (hours)"
                                    placeholder="Enter a number in hours here"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    fieldContainerStyle={fieldContainerStyle}
                                />
                            }
                        </SectionCard>

                        <ContentCollapsingSection title="Advanced ASR Settings" icon={advancedSettingsIcon} isCollapsedByDefault={true} className={collapsedSection}>
                            <LabelWrapper>
                                <Label>ASR Provider</Label>
                            </LabelWrapper>
                            <CustomDropdown
                                handleOnChange={handleDropDownChange}
                                fieldName="asrProvider"
                                options={availableASRProviders.map(asr => ({ value: asr, label: asr }))}
                                size="medium"
                                className={dropDownStyleMedium}
                                value={props.values.asrProvider}
                            />
                            <CustomTextField
                                name="asrModel"
                                disabled={false}
                                value={props.values.asrModel}
                                label="ASR Model"
                                placeholder="Type your preferred ASR model here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                            <CustomTextField
                                name="asrTier"
                                disabled={false}
                                value={props.values.asrTier}
                                label="ASR Tier"
                                placeholder="Type your preferred ASR tier here"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                            <RadioGroupLabel>Process Audio</RadioGroupLabel>
                            <CustomRadioGroup
                                checkContainerStyle={checkContainerStyle}
                                selectedOptionStyle={selectedOptionStyle}
                                onChange={(e) => handleOnChange("shouldProcessAudio", e.value)}
                                value={props.values.shouldProcessAudio}
                                options={processAudioOptions}
                            />
                            <CustomTextField
                                name="ttsPronunciationsMap"
                                disabled={false}
                                value={props.values.ttsPronunciationsMap}
                                label="TTS Pronunciations Map"
                                placeholder="example: {'pan': '{p11@n}'}, format: escaped json"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                fieldContainerStyle={fieldContainerStyle}
                            />
                        </ContentCollapsingSection>
                    </ContentWrapper>
                </CustomScrollbars>
                <FooterBar>
                    <Button disabled={props.isSubmitting} loading={props.isSubmitting} type="submit" themes={['primary', 'start']} text="Save & Close" />
                    <Button disabled={props.isSubmitting} loading={props.isSubmitting} type="button" themes={['secondary', 'end']} text="Cancel" onClick={() => props.closeTelephonyRequestForm()} />
                </FooterBar>
            </form>
        </ModalLayout >
    )
}

const collapsedSection = css`
    *.card-title-container {
        padding: 24px 24px 24px 40px;
        h4 {
            font-size: 14px;
        }
    }
`;


const RadioGroupLabel = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin: 0;
`;

const CustomRadioGroup = styled(RadioGroup)`
    margin: 0 0 26px 0;
`;

const CustomDropdown = styled(StyledDropdown)`
    margin: 0 0 24px 0;
`;

const CustomTextField = styled(TextField)`
    margin-bottom: 0 0 26px 0;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const Label = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin: 0 0 4px 0px;
`;

const LabelLink = styled.a`
    font-weight: 400;
    font-size: 14px;
    margin: 0 0 4px 8px;
    color: ${color_text_link}
`;

const dropDownStyleMedium = css`
    margin-bottom: 32px;
`;

const labelStyle = css`
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};
    margin: 0 0 4px 0px;
`;

const checkContainerStyle = css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: ${style_border_default};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const selectedOptionStyle = css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: ${color_gradients_green_shift};
`;

const fieldContainerStyle = css`
    height: 48px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 36px 36px 0 36px;
`;


const CustomVoiceRow = styled.div`
    display: flex;
    flex-direction: row;
`;
const CustomVoiceRowLeft = styled(CustomTextField)`
    margin-right: 8px;
`;
const CustomVoiceRowRight = styled(CustomTextField)`
    margin-left: 8px;
`;

const PhoneNumberRow = styled.div`
    display: flex;
    align-items: flex-end;  // Changed from center to flex-end
    gap: 8px;
`;

const CountryDropdownWrapper = styled.div`
    flex: 0 0 160px;
`;

const PhoneNumberWrapper = styled.div`
    flex: 1;
`;

const SectionCard = styled.div`
    margin-bottom: 24px;
    padding: 24px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const SectionTitle = styled.h3`
    font-size: 16px;
    font-weight: 600;
    color: ${color_shades_darkest};
    margin: 0 0 24px 0;
`;

export default TelephonyDeployment;